export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    // { label: "openid", prop: "openid", slots: "openid" },
    { label: "店铺名称", prop: "store_name", width: 250 },
    { label: "联系人", prop: "boss_name", width: 250 },
    { label: "手机号", prop: "store_phone", width: 250  },
    { label: "地址", prop: "store_addr", width: 250  },
    // { label: "店长微信是否绑定", prop: "openid", slots: "openid" },
    // { label: "等级", prop: "grade", slots: "grade" },
    // { label: "到期时间", prop: "vip_date" },
    {
      label: "创建时间",
      prop: "create_time",
      width: 250,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 250,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
