export default function() {
  /* 表格筛选 */
  return [
    {
      label: "病种",
      prop: "doctor",
      type: "select",
      option: [
        { id: 1, name: "高建", code: "001" },
        { id: 2, name: "小明", code: "002" },
      ],
    },
    {
      label: "状态",
      type: "select",
      prop: "value1",
      option: [
        { id: 1, name: "出院", code: "001" },
        { id: 2, name: "在院", code: "002" },
      ],
      // option: this.$store.state.list,
    },
    {
      label: "时间：",
      prop: "value2",
      type: "datePicker",
      options: {
        width: "220px",
        prop: "value3",
      },
    },
  ];
}
