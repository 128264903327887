<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- 封装selectForm筛选 -->
    <el-button
      type="primary"
      size="small"
      v-waves
      @click="handleAdd"
      v-if="this.userInfo.grade && this.userInfo.grade !== 4"
      >新增</el-button
    >
    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="openid" slot-scope="{ row }">
          <el-tag type="success" effect="dark" size="mini" v-if="row.openid">
            已绑定
          </el-tag>
          <el-tag type="danger" effect="dark" size="mini" v-else>
            未绑定
          </el-tag>
        </template>
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button
              style="color: #afb86d"
              size="mini"
              type="text"
              @click="handleEdit(row)"
              primary
              :disabled="userInfo.grade == 4"
              >编辑</el-button
            >
            <el-button
              style="color: #afb86d"
              size="mini"
              type="text"
              @click="handleErweima(row)"
              primary
              >二维码</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              :disabled="userInfo.grade == 4"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>
      <!-- 新增,修改表单弹窗 -->
      <el-dialog
        :title="dialogName"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogForm" label-width="80px">
          <el-form-item label="店铺名称" required>
            <el-input clearable v-model="dialogForm.store_name"></el-input>
          </el-form-item>
          <!-- <el-form-item label="店长ID">
            <el-input clearable v-model="dialogForm.openid"></el-input>
          </el-form-item> -->
          <el-form-item label="联系人" required>
            <el-input clearable v-model="dialogForm.boss_name"></el-input>
          </el-form-item>
          <el-form-item label="手机号" required>
            <el-input
              clearable
              v-model="dialogForm.store_phone"
              placeholder="格式为11位数手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="店铺地址" required>
            <el-input
              type="textarea"
              clearable
              v-model="dialogForm.store_addr"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button class="addBtn" size="small" type="primary" @click="save" v-waves
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 二维码弹窗 -->
      <el-dialog
        title="生成二维码"
        :visible.sync="dialogCodeVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogCodeForm" label-width="80px">
          <el-form-item label="门店">
            <el-input
              clearable
              v-model="dialogCodeForm.store_name"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="渠道号(选填)">
            <el-input
              clearable
              v-model="dialogCodeForm.tableNumber"
              placeholder="如01, A01,请勿填写汉字"
              maxlength="5"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" size="small" @click="dialogCodeVisible = false"
            >取 消</el-button
          >
          <el-button
          class="addBtn"
            size="small"
            type="primary"
            @click="handleCreateCode"
            v-waves
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
import filterQueryFormItems from "./filterQueryFormItems";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
import {
  queryMyselfStore,
  updateStore,
  deleteStore,
  addStore,
} from "@/services/mall/store";
import { createCode } from "@/services/mall/getCode";

import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    Percentage,
    FillingStatus,
    KindTab,
  },
  async created() {
    this.getListData();
  },
  data() {
    return {
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
      /* 弹窗 */
      dialogName: "",
      dialogVisible: false,
      dialogForm: {},
      /* 二维码弹窗 */
      dialogCodeVisible: false,
      dialogCodeForm: {},
    };
  },
  computed: {
    filterQueryFormItems,
    listHeader,
    ...mapState(["userInfo"]),
  },
  watch: {},
  methods: {
    async getListData(listQuery) {
      this.loading = true;
      const { data, code, count } = await queryMyselfStore({
        ...(listQuery || this.listQuery),
        store_id: this.userInfo.store_id,
        admin_id: this.userInfo.id,
      });
      // console.log("============getListData===============", data);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
      }
      setTimeout(() => {
        this.loading = false;
      }, 600);
    },
    /* 搜索按钮 */
    async search(listQuery) {
      console.log("listQuery", listQuery);
      this.listQuery = listQuery;
      this.getListData();
    },

    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    /* 新增 */
    handleAdd() {
      // if (!this.userInfo.grade) {
      //   this.$message({
      //     type: "error",
      //     message: "该账号无法添加更多门店,请联系管理员!",
      //   });
      //   return;
      // }
      // if (this.userInfo.grade === 1 && this.tableData.length >= 1) {
      //   this.$message({
      //     type: "error",
      //     message: "该账号无法添加更多门店,请联系管理员!",
      //   });
      //   return;
      // }
      // if (this.userInfo.grade === 2 && this.tableData.length >= 5) {
      //   this.$message({
      //     type: "error",
      //     message: "该账号无法添加更多门店,请联系管理员!",
      //   });
      //   return;
      // }
      if (this.tableData.length >= 10) {
        this.$message({
          type: "error",
          message: "该服务器无法添加更多门店,请联系管理员!",
        });
        return;
      }
      this.dialogVisible = true;
      this.dialogName = "创建门店";
      this.dialogForm = {};
    },

    /* 修改弹窗 */
    handleEdit(row) {
      console.log(row);
      this.dialogVisible = true;
      this.dialogName = "编辑门店";
      this.dialogForm = JSON.parse(JSON.stringify(row));
    },
    /* 生成二维码弹窗 */
    handleErweima(row) {
      console.log(row);
      this.dialogCodeVisible = true;
      this.dialogCodeForm = JSON.parse(JSON.stringify(row));
    },
    /* 保存 */
    save() {
      //必填字段校验
      if (
        !this.dialogForm.boss_name ||
        !this.dialogForm.store_phone ||
        !this.dialogForm.store_addr ||
        !this.dialogForm.store_name
      ) {
        this.$message.error("请填写必填字段");
        return;
      }

      /* 账号格式校验 */
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.dialogForm.store_phone)) {
        this.$message.error("手机号格式错误");
        return;
      }

      if (this.dialogName === "创建门店") {
        addStore({ ...this.dialogForm, admin_id: this.userInfo.id }).then(
          (res) => {
            if (res.code === 200) {
              this.listQuery.pageNumber = 1;
              /* TODO:重复的获取店铺信息的请求,可以优化 */
              this.$store.dispatch("getUserInfoAction").then((res) => {
                this.getListData();
              });
              this.$message({
                type: "success",
                message: "创建成功!",
              });
              this.dialogVisible = false;
            } else {
              this.$message({
                type: "error",
                message: res.data,
              });
            }
          }
        );
      }
      if (this.dialogName === "编辑门店") {
        updateStore(this.dialogForm).then((res) => {
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.dialogVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.data,
            });
          }
        });
      }
    },
    /* 删除 */
    handleDelete(row) {
      console.log(row.id);
      this.$confirm(
        `此操作将永久删除'${row.store_name}'店铺吗, 是否继续?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteStore({ id: row.id }).then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getListData();
          });
        })
        .catch(() => {});
    },

    /* 确定生成二维码 */
    handleCreateCode() {
      createCode({
        store_id: this.dialogCodeForm.store_id,
        tableNumber: this.dialogCodeForm.tableNumber,
      }).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "创建成功,前往下载!",
          });
          this.dialogCodeVisible = false;
          setTimeout(() => {
            window.open(res.data, "_blank");
          }, 1000);
        } else {
          this.$message({
            type: "error",
            message: res.data,
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}
</style>
