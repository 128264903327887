<!-- 种类列表 -->
<template>
  <ul class="kindTab">
    <li
      v-for="(item, index) in tabData"
      :key="index"
      @click="itemBtn(item, index)"
      :class="{
        active: active === index,
      }"
    >
      <p class="title">
        {{ item.title }}
      </p>
      <img :src="item.url" alt="" srcset="" v-if="active !== index" />
      <img :src="item.activeUrl" alt="" srcset="" v-else />
    </li>
  </ul>
</template>

<script>
export default {
  name: "KindTab",
  components: {},
  props: {
    tabData: {
      type: Array,
    },
  },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    itemBtn(item, index) {
      console.log(item, index);
      this.$emit("clickTableTab", item);
      this.active = index;
    },
  },
};
</script>

<style scoped lang="less">
.kindTab {
  width: 100%;
  text-align: 40px;
  position: relative;
  color: #000000;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // justify-content: center;
}
.kindTab li {
  width: 160px;
  height: 70px;
  font-size: 14px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  .title {
    margin: 0 20px 0 0;
    // width: 50px;
    // border: 1px solid red;
  }
  img {
    display: block;
    width: 30px;
  }
}
.kindTab li:hover {
  cursor: pointer;
}

.active {
  background: #1d6aff !important;
  color: #ffffff !important;
}
</style>
