<!-- 0待填报 1已填报 -->
<template>
  <div class="statusBox">
    <div
      :class="[
        value == 0 ? 'red' : value == 1 ? 'green' : 'yellow',
        'status_icon',
      ]"
    ></div>
    <span>
      {{
        value == 0
          ? "待处理"
          : value == 1
          ? "已处理"
          : "——"
      }}</span
    >
  </div>
</template>

<script>
export default {
  name: "FillingStatus",
  components: {},
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.statusBox {
  height: 100%;
  display: flex;
  align-items: center;
}
.status_icon {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 10px;
}
.green {
  background: #30a46c;
}
.red {
  background: #ef7a59;
}
.yellow {
  background: #fb9a0e;
}
</style>
