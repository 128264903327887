import axios from "axios";
import { request } from "@/utils/request";

/**
 * 生成门店二维码
 * @param {*} data
 * @returns
 */
export function createCode(data) {
  return request({
    url: `/getCode/create`,
    method: "POST",
    data,
    isDecrypt: false,
    isEncrypt: true,
  });
}
