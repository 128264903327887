<template>
  <div>
    <!-- 固定表头 -->
    <el-table
      class="elTable"
      height="calc(100vh - 220px)"
      :columns="columns"
      :data="tableData"
      empty-text=" "
    >
      <template v-for="(item, index) in columns">
        <!-- 普通渲染 -->
        <el-table-column
          :key="index"
          v-if="!item.slots && !item.hidden"
          :fixed="item.fixed ? item.fixed : false"
          :type="item.type"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
        </el-table-column>
        <el-table-column
          :key="index + '_slot'"
          v-if="item.slots && !item.hidden"
          :fixed="item.fixed ? item.fixed : false"
          :type="item.type"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <!-- 渲染插槽 -->
          <template slot-scope="scope">
            <slot :name="item.slots" :row="scope.row"> </slot>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <el-pagination
      v-if="total > 0"
      style="margin: 20px 0 0;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.pageNumber"
      :page-sizes="[10, 50, 100, 500, 1000]"
      :page-size="listQuery.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "SelectForm",
  props: {
    columns: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    total: {
      type: Number,
    },
  },
  data() {
    return {
      pageSize: 20,
      current: 4,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
      },
    };
  },
  watch: {
    pageSize(val) {
      console.log("pageSize", val);
    },
    current(val) {
      console.log("current", val);
    },
  },
  methods: {
    /* 分页 */
    handleCurrentChange(e) {
      this.$emit("handleCurrentChange", e);
    },
    handleSizeChange(e) {
      this.$emit("handleSizeChange", e);
    },
  },
};
</script>


<style scoped lang="less">
.selectForm {
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  .elTable {
    width: 100%;
    border: none !important;
  }
}

// 表格
::v-deep .el-table {
  border: 2px solid rgb(255, 255, 255) !important;
}

::v-deep .el-table th {
  background-color: #f9f9f9; /* Custom header cell background color */
  border: 2px solid white !important; /* White border for header cells */
}

::v-deep .el-table td {
  background-color: #fff; /* Custom body cell background color */
  border: 2px solid white !important; /* White border for body cells */
}

::v-deep .el-table .el-table__header-wrapper th {
  border: 2px solid white !important; /* White border for header cells */
}

::v-deep .el-table tr:hover td {
  background-color: #f9f9f9 !important;
}

::v-deep .el-table::before,
::v-deep .el-table::after {
  display: none;
}

/* Active page number */
::v-deep .el-pagination .el-pager li.active,
::v-deep .el-pagination .el-pager .el-pagination__item.is-active {
  color: #afb86d; 
}

/* Hover state for page numbers */
::v-deep .el-pagination .el-pager li:not(.active):hover,
::v-deep .el-pagination .el-pager .el-pagination__item:not(.is-active):hover {
  color: #afb86d;
  border-color: #afb86d;
}

/* Active state for previous/next buttons */
::v-deep .el-pagination .btn-next:hover,
::v-deep .el-pagination .btn-prev:hover {
  color: #afb86d !important;
}

/* Dropdown in pagination (e.g., for selecting page size) */
::v-deep .el-pagination .el-pagination__sizes .el-select .el-input__inner,
::v-deep
  .el-pagination
  .el-pagination__sizes
  .el-select
  .el-input.is-focus
  .el-input__inner,
::v-deep .el-pagination .el-pagination__sizes .el-input__inner {
  border: none;
}

/* Dropdown item hover state */
::v-deep .el-pagination .el-pagination__sizes .el-select-dropdown__item:hover {
  color: #afb86d;
  border-color: #afb86d;
}

/* Dropdown selected item text color */
::v-deep
  .el-pagination
  .el-pagination__sizes
  .el-select-dropdown__item.selected {
  color: #afb86d !important;
}
</style>
