<!-- 90以上绿色 80以下红色 90以下黄色 -->
<template>
  <div :class="value < 0.80 ? 'red' : value > 0.90 ? 'green' : 'yellow'">
    {{ parseInt(value * 100) }}%
  </div>
</template>

<script>
export default {
  name: "Percentage",
  components: {},
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.green {
  color: #30a46c;
}
.red {
  color: #e63f07;
}
.yellow {
  color: #fb9a0e;
}
</style>
